import React from "react"
import { MDBContainer, MDBFooter } from "mdbreact"

const Footer = () => {
  return (
    <MDBFooter color="primary-color" className="font-small mt-4">
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
        <div className='footer'>
            <p>
                2021 - <strong>HackerSpace™</strong>
                <br />
                Unless otherwise stated, content is available under the
                <br />
                <a href='https://creativecommons.org/licenses/by-sa/4.0/'>
                    Creative Commons Attribution-ShareAlike Licensce
</a>
            </p>
          <div>
          HackerSpace™ is an initiative under <a href='https://fossnss.org'>FOSSNSS</a>, supported by <a href="https://hackclub.com">HackClub</a>
            </div>
        </div>

        </MDBContainer>
      </div>
    </MDBFooter>
  )
}

export default Footer
